<template>
  <div>
    <help></help>
  </div>
</template>

<script>
import { default as Help } from "@/components/widgets/Help";
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import vSelect from 'vue-select';
import {
  default as router
} from '@/router';

export default {
  name: 'UserSettings',
  computed: {
    ...mapGetters({
    })
  },
  mounted: function () {
    let user = localStorage.getItem('user');
    this.user = JSON.parse(user);
    store.commit('selected_patient', false);
  },
  data () {
    return {
      user: {}
    };
  },
  created: function () {},
  filters: {},
  methods: {},
  components: {
    vSelect,
    Help
  }
};

</script>

